import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import ContactOne from "../elements/contact/ContactOne";
import TabTwo from "../elements/tab/TabTwo";

const VangoTechCorp = () => {
  return (
    <>
      <SEO title='Vango Tech - Technology Consulting' />
      <main className='page-wrapper'>
        {/* <HeaderTopNews /> */}
        <HeaderTwo btnStyle='round' HeaderSTyle='header-not-transparent' />

        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 bg-transparent height-650'
          style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <p className='description'>
                    <b>Global Tecnology and Product Consulting.</b> <br />A
                    unique approach to technology solutions.
                  </p>
                  <h1 className='title theme-gradient display-two'>
                    {" "}
                    <Typed
                      strings={[
                        "Efficient",
                        "Global",
                        "Strategic",
                        "Innovative",
                        "Creative",
                        "Cost-Effective",
                        "Partners",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <div className='description' style={{ fontSize: "18px" }}>
                    We help your business achieve your technology and strategic
                    goals with affordable and high quality on-shore & off-shore
                    development expertise.
                  </div>
                  <div className='button-group'>
                    <Link
                      className='btn-default btn-medium btn-border round btn-icon'
                      to='#'>
                      Contact Us{" "}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div
          className='rn-service-area rn-section-gap anchorScroll'
          id='services'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Our services'
                  title='What we can do.'
                  description=''
                />
              </div>
            </div>
            <ServiceFive serviceStyle='gallery-style' textAlign='text-start' />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />

        {/* Start Team Area  */}
        <div className='rwt-team-area rn-section-gap anchorScroll' id='skills'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Our Skills'
                  title='Some of the technologies we work with.'
                  description=''
                />
              </div>
            </div>
            <TabTwo />
            {/* <TeamTwo
              column='col-lg-4 col-md-6 col-12'
              teamStyle='team-style-default style-two'
            /> */}
          </div>
        </div>
        {/* End Team Area  */}
        {/* <Separator /> */}
        {/* Start Mission Area   */}
        {/* <Mission /> */}
        {/* Start Mission Area  */}
        <Separator />

        {/* Portfolio Area */}
        <div className='main-content'>
          <div
            className='rwt-portfolio-area rn-section-gap anchorScroll'
            id='portfolio'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Our Work'
                    title='We bring your vision to life.'
                    description=''
                  />
                </div>
              </div>
              <PortfolioOne Column='col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio' />
            </div>
          </div>
        </div>

        {/* End Portfolio Area */}

        <Separator />

        <div
          className='rwt-contact-area rn-section-gap anchorScroll'
          id='contact'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 mb--40'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Contact Us'
                  title='Get in touch with us!'
                  description=''
                />
              </div>
            </div>
            <ContactOne />
          </div>
        </div>
        <Separator />

        {/* <FooterTwo /> */}
        <Copyright />
      </main>
    </>
  );
};
export default VangoTechCorp;
