import React from "react";

import { PopupButton } from "react-calendly";
import SectionTitle from "../sectionTitle/SectionTitle";

const AboutOne = () => {
  return (
    <div className='rwt-about-area rn-section-gap anchorScroll' id='about_us'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <SectionTitle
              textAlign='text-center'
              radiusRounded=''
              subtitle='About Us'
              title='Who we are.'
              description=''
            />
          </div>
        </div>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <img className='w-100' src='./images/about/brain.jpg' alt='' />
            </div>
          </div>

          <div className='col-lg-7 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                {/* <h2 className='title'>
                  Unique Business <br />{" "}
                  <Typed
                    className='theme-gradient'
                    strings={["Consulting.", "Finance.", "Agency."]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2> */}
                <p>
                  We are a global technology and product consulting company that
                  partners with businesses to provide exceptional and creative
                  solutions to their broad range of technology needs in a
                  cost-effective manner.{" "}
                </p>
                <p>
                  Vango Tech came together to solve interesting and hard
                  problems. Created by a team of global passionate
                  technologists, our mixed and vast experience resulted in the
                  perfect creative and analytical mix.
                </p>
                <div>
                  From early stage startups to enterprise size companies, we
                  provide tailor made solutions and resources to fit the needs
                  without sacrificing quality. Our range includes and goes
                  beyond the following:
                  <ul className='aboutList'>
                    <li>
                      Time boxed and budget defined software and design
                      projects.{" "}
                    </li>
                    <li>
                      Full staff augmentation: short and long term resources.{" "}
                    </li>
                    <li>
                      Data architecture and engineering resources to elevate the
                      companies data practice and maturity.
                    </li>
                    <li>
                      Full cycle product management and application development.
                    </li>
                    <li>
                      Executive leadership help for startups to navigate growth
                      stages to establish process and vision.
                    </li>
                  </ul>
                </div>
                <p>
                  We embrace chaos, we thrive in creating order and value out of
                  it.
                </p>

                <div className='read-more-btn mt--40'>
                  <PopupButton
                    url='https://calendly.com/vangotech/discovery-call'
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root")}
                    text='Schedule Discovery Call'
                    className='btn-default'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
