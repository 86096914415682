import React from "react";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";

const Nav = () => {
  return (
    <ul className='mainmenu'>
      <li>
        <Link to='/'>Home</Link>
      </li>
      <li>
        <a href='/#services'>Services</a>
        {/* <Link to='/#services'>Services</Link> */}
      </li>
      <li>
        <a href='/#about_us'>About</a>
        {/* <Link to='/#about_us'>About</Link> */}
      </li>
      <li>
        <a href='/#skills'>Skills</a>
        {/* <Link to='/#portfolio'>Portfolio</Link> */}
      </li>
      <li>
        <a href='/#portfolio'>Portfolio</a>
        {/* <Link to='/#portfolio'>Portfolio</Link> */}
      </li>
      <li>
        <a href='/#contact'>Contact</a>
      </li>
      <li>
        <p>
          <PopupButton
            url='https://calendly.com/vangotech/discovery-call'
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
            text='Schedule'
            className='btn-default'
          />
        </p>
      </li>
    </ul>
  );
};
export default Nav;
