import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const MobileTechnologies = [
  {
    id: 1,
    image: "./images/technologies/apple1.svg",
    name: "Native Apple (iOS/iPadOS/watchOS)",
  },
  {
    id: 2,
    image: "./images/technologies/android-icon2.svg",
    name: "Native Android",
  },
  {
    id: 3,
    image: "./images/technologies/react.svg",
    name: "React Native",
  },
  {
    id: 4,
    image: "./images/technologies/flutter.svg",
    name: "Flutter",
  },
  {
    id: 5,
    image: "./images/technologies/swift.svg",
    name: "Swift/SwiftUI",
  },
  {
    id: 6,
    image: "./images/technologies/kotlin.svg",
    name: "Kotlin",
  },
];

const FrontEndTechnologies = [
  {
    id: 7,
    image: "./images/technologies/react.svg",
    name: "React Native",
  },
  {
    id: 8,
    image: "./images/technologies/flutter.svg",
    name: "Flutter",
  },
  {
    id: 9,
    image: "./images/technologies/angular-icon-1.svg",
    name: "AngularJS",
  },
  {
    id: 10,
    image: "./images/technologies/django.svg",
    name: "Django",
  },
  {
    id: 11,
    image: "./images/technologies/vue-9.svg",
    name: "VUE",
  },
  {
    id: 12,
    image: "./images/technologies/typescript-2.svg",
    name: "Typescript",
  },
  {
    id: 13,
    image: "./images/technologies/javascript-1.svg",
    name: "Javascript",
  },
  {
    id: 14,
    image: "./images/technologies/css-3.svg",
    name: "CSS",
  },
  {
    id: 15,
    image: "./images/technologies/html-1.svg",
    name: "HTML",
  },
  {
    id: 16,
    image: "./images/technologies/php-1.svg",
    name: "PHP",
  },
  {
    id: 17,
    image: "./images/technologies/tailwind-css-2.svg",
    name: "Tailwind",
  },
];

const BackEndTechnologies = [
  {
    id: 18,
    image: "./images/technologies/ruby.svg",
    name: "Ruby",
  },
  {
    id: 19,
    image: "./images/technologies/python-5.svg",
    name: "Python",
  },
  {
    id: 20,
    image: "./images/technologies/nodejs-1.svg",
    name: "NodeJS",
  },
  {
    id: 21,
    image: "./images/technologies/java-4.svg",
    name: "Java",
  },
  {
    id: 22,
    image: "./images/technologies/dot-net-core-7.svg",
    name: ".NET",
  },
  {
    id: 23,
    image: "./images/technologies/graphql-logo-2.svg",
    name: "GraphQL",
  },
  {
    id: 24,
    image: "./images/technologies/mqtt.svg",
    name: "MQTT",
  },
  {
    id: 25,
    image: "./images/technologies/fastapi-1.svg",
    name: "FastAPI",
  },
  {
    id: 26,
    image: "./images/technologies/restapi.svg",
    name: "REST API",
  },
  {
    id: 27,
    image: "./images/technologies/microservices.svg",
    name: "Microservices Architecture",
  },
];

const InfraTechnologies = [
  {
    id: 28,
    image: "./images/technologies/aws-2.svg",
    name: "AWS",
  },
  {
    id: 29,
    image: "./images/technologies/google-cloud-1.svg",
    name: "GCP",
  },
  {
    id: 30,
    image: "./images/technologies/azure-1.svg",
    name: "Azure",
  },
  {
    id: 31,
    image: "./images/technologies/firebase-1.svg",
    name: "Firebase",
  },
  {
    id: 32,
    image: "./images/technologies/heroku-4.svg",
    name: "Heroku",
  },
  {
    id: 33,
    image: "./images/technologies/vagrant-icon.svg",
    name: "Vagrant",
  },
];

const DesignTechnologies = [
  {
    id: 34,
    image: "./images/technologies/figma.svg",
    name: "Figma",
  },
  {
    id: 35,
    image: "./images/technologies/sketch-2.svg",
    name: "Sketch",
  },
  {
    id: 36,
    image: "./images/technologies/adobe-xd-1.svg",
    name: "Adobe XD",
  },
  {
    id: 37,
    image: "./images/technologies/blender-2.svg",
    name: "Blender",
  },
  {
    id: 38,
    image: "./images/technologies/unity-69.svg",
    name: "Unity",
  },
  {
    id: 39,
    image: "./images/technologies/3dsmax.svg",
    name: "Autodesk 3DS Max",
  },
  {
    id: 40,
    image: "./images/technologies/invision.svg",
    name: "InVision",
  },
];

const DataTechnologies = [
  {
    id: 41,
    image: "./images/technologies/postgresql.svg",
    name: "PostgreSQL",
  },
  {
    id: 42,
    image: "./images/technologies/mysql-6.svg",
    name: "MySQL",
  },
  {
    id: 43,
    image: "./images/technologies/influxdb.svg",
    name: "InfluxDB",
  },
  {
    id: 44,
    image: "./images/technologies/grafana.svg",
    name: "Grafana",
  },
  {
    id: 45,
    image: "./images/technologies/elasticsearch.svg",
    name: "Elastic",
  },
  {
    id: 46,
    image: "./images/technologies/microsoft-sql-server-1.svg",
    name: "SQL Server",
  },
  {
    id: 47,
    image: "./images/technologies/mongodb-icon-1.svg",
    name: "MongoDB",
  },
  {
    id: 48,
    image: "./images/technologies/aws-redshift-logo.svg",
    name: "RedShift",
  },
  {
    id: 49,
    image: "./images/technologies/neo4j.svg",
    name: "Neo4J",
  },
  {
    id: 50,
    image: "./images/technologies/hadoop.svg",
    name: "Hadoop",
  },
  {
    id: 51,
    image: "./images/technologies/kafka.svg",
    name: "Kafka",
  },
  {
    id: 52,
    image: "./images/technologies/spark-2.svg",
    name: "Spark",
  },
  {
    id: 53,
    image: "./images/technologies/Snowflake.svg",
    name: "Snowflake",
  },
];

const ToolsBackofficeTechnologies = [
  {
    id: 54,
    image: "./images/technologies/jira-3.svg",
    name: "JIRA",
  },
  {
    id: 55,
    image: "./images/technologies/bitbucket-icon.svg",
    name: "BitBucket",
  },
  {
    id: 56,
    image: "./images/technologies/gitlab.svg",
    name: "GitLab",
  },
  {
    id: 57,
    image: "./images/technologies/github-icon-2.svg",
    name: "GitHub",
  },
  {
    id: 58,
    image: "./images/technologies/microsoft-visual-studio-app-center.svg",
    name: "AppCenter",
  },
  {
    id: 59,
    image: "./images/technologies/xcode.svg",
    name: "Xcode",
  },
  {
    id: 60,
    image: "./images/technologies/android-4.svg",
    name: "Android Studio",
  },
  {
    id: 61,
    image: "./images/technologies/zoho-1.svg",
    name: "Zoho",
  },
  {
    id: 62,
    image: "./images/technologies/salesforce-2.svg",
    name: "Salesforce",
  },
  {
    id: 63,
    image: "./images/technologies/hubspot-1.svg",
    name: "Hubspot",
  },
  {
    id: 64,
    image: "./images/technologies/zendesk-3.svg",
    name: "Zendesk",
  },
];

const TabTwo = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <Tabs>
            <div className='row row--30 align-items-center'>
              {/* <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-02.jpg' alt="Corporate React Template" />
                                </div> */}
              <div className='col-lg-12 mt_md--40 mt_sm--40'>
                <div className='rn-default-tab style-two'>
                  <div className='tab-button-panel'>
                    <TabList
                      className='tab-button'
                      style={{ justifyContent: "center" }}>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Mobile Development</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Frontend Web</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Backend Development</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Data Engineering</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Infrastructure</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Design</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Tools & Backoffice</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className='tab-content-panel'>
                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {MobileTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {FrontEndTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {BackEndTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {DataTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {InfraTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {DesignTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner technologyContent'>
                          {ToolsBackofficeTechnologies.map((data, index) => (
                            <div key={data.id} className='technologyItem'>
                              <img src={`${data.image}`} alt={`${data.name}`} />
                              <p>{`${data.name}`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwo;
