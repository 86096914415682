import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";

const PortfolioDetailsContent = ({ data }) => {
  return (
    <div className='rwt-portfolio-details rn-section-gap'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-10 offset-lg-1'>
            <div className='inner'>
              <div className='details-list'>
                <div className='thumbnail alignwide portfolioBanner'>
                  {data.btnPrevious.link !== "" ? (
                    <Link
                      to={process.env.PUBLIC_URL + `${data.btnPrevious.link}`}>
                      <FiArrowLeftCircle size='60' />
                    </Link>
                  ) : (
                    <Link
                      style={{ visibility: "hidden" }}
                      to={process.env.PUBLIC_URL + `${data.btnPrevious.link}`}>
                      <FiArrowLeftCircle size='60' />
                    </Link>
                  )}
                  <img
                    className='radius w-100'
                    src={`${process.env.PUBLIC_URL}/${data.largeImage}`}
                    alt=''
                  />
                  {data.btnNext.link !== "" ? (
                    <Link to={process.env.PUBLIC_URL + `${data.btnNext.link}`}>
                      <FiArrowRightCircle size='60' />
                    </Link>
                  ) : (
                    <Link
                      style={{ visibility: "hidden" }}
                      to={process.env.PUBLIC_URL + `${data.btnNext.link}`}>
                      <FiArrowRightCircle size='60' />
                    </Link>
                  )}
                </div>

                <div
                  className='row mt--40 row--30 ml--0 mr--0'
                  style={{ justifyContent: "space-between" }}>
                  <div className='col-lg-4 portfolioClientDetail'>
                    <div className='content-left'>
                      <h4 className='title'>{data.client}</h4>
                      <div className='single-list-wrapper'>
                        <div className='single-list'>
                          <label>Date:</label>
                          <span>{data.date}</span>
                        </div>
                        <div className='single-list'>
                          <label>Client:</label>
                          <span>{data.client}</span>
                        </div>
                        <div className='single-list'>
                          <label>Category:</label>
                          <span>{data.category}</span>
                        </div>
                      </div>
                      {/* <div className='view-btn mt--50'>
                        <a
                          className='btn-default btn-large round'
                          href={`${data.btn.link}`}>
                          {data.btn.buttontext}
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className='col-lg-7 mt_md--30 mt_sm--30'>
                    <div className='content-right'>
                      <h5 className='subtitle'>{data.subtitle}</h5>
                      <div
                        className='description'
                        dangerouslySetInnerHTML={{ __html: data.body }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='single-list-wrapper'>
                <div className='portfolioTechnologyTitle'>
                  <label>Technolgies:</label>
                  {/* <span>{data.category}</span> */}
                </div>
                <div className='rn-tab-content portfolioTechnology'>
                  <div className='inner portfolioTechContent'>
                    {data.technologies.map((tech, index) => (
                      <div key={tech.id} className='portfolioTechItem'>
                        <img
                          src={`${process.env.PUBLIC_URL}/${tech.image}`}
                          alt={`${tech.name}`}
                        />
                        <p>{`${tech.name}`}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='portfolio-gallery mt--40'>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
                      alt='Portfolio_01'
                    />
                  </div>
                </div>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
                      alt='Portfolio_02'
                    />
                  </div>
                </div>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                      alt='Portfolio_03'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailsContent;
