import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import VangoTech from "./pages/VangoTech";
// Elements import Here
import PortfolioDetails from "./pages/PortfolioDetails";

// Import Css Here
import "./assets/scss/style.scss";
import ScrollToAnchor from "./components/scrollToAnchor/ScrollToAnchor";

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <ScrollToAnchor />
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={VangoTech}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`}
            exact
            component={PortfolioDetails}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
