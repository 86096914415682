import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/service/galery-image-01.png",
    icon: "/images/service/sofwareEngineering.svg",
    title: "Software Engineering",
    description:
      "Unlock Quality and Cost-Effective Software Engineering! Our expert excels in all phases of the software development lifecycle, specializing in mobile and web development. From planning and design to coding,testing, and deployment, we optimize project costs while ensuring top-notch results with our talented team.",
  },
  {
    image: "/images/service/galery-image-01.png",
    icon: "/images/service/dataEngineering.svg",
    title: "Data Architecture & Engineering",
    description:
      "Unleash your company data potential! We focus in guiding businesses on the path to data maturity. We ensure optimization of data quality, architecture, and engineering for advanced analytics by leveraging AI and machine learning.",
  },
  {
    image: "/images/service/galery-image-01.png",
    icon: "/images/service/UIUX.svg",
    title: "UI/UX Design",
    description:
      "Dive into the world of 2D and 3D design as we transform your digital products into immersive and visually stunning experiences. Our specialized team collaborates with your existing UI or provides a new, captivating experience, ensuring your brand stands out in the digital landscape.",
  },
  {
    image: "/images/service/galery-image-02.png",
    icon: "/images/service/staffAugmentation.svg",
    title: "Staff Augmentation",
    description:
      "Grow Your Team by harnessing top-tier,  cost-effective resources to elevate your project capabilities. Our nearshore and offshore vetted talent pool allows you to seamlessly scale up while optimizing costs without compromising on quality.",
  },
  {
    image: "/images/service/galery-image-03.png",
    icon: "/images/service/leadership.svg",
    title: "Technology Leadership & Advisory",
    description:
      "Solidify your business leadership with our CTO and senior leadership expertise. We provide highly skilled talent capable of driving vision, managing large teams across product, data, and engineering domains. We've empowered startups to scale, refine growth processes, and shift from early stage to growth and vision-focused stages. We sit at the table to drive your business through digital transformation and sustainable growth.",
  },
  {
    image: "/images/service/galery-image-03.png",
    icon: "/images/service/backoffice.svg",
    title: "Backoffice Apps & Integration",
    description:
      "Streamline your business processes, from customer acquisition to post-sale service. Our team ensures seamless coordination across a range of critical systems like Marketing, CRM, Commerce, ERP, and more, ensuring smooth data flow and integrations for a enhanced customer experience, all culminating in a comprehensive Customer 360 view.",
  },
];
const ServiceFive = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-12 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}>
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='inner'>
                <div className='content'>
                  <div className='text-center mb--20'>
                    <img src={val.icon} alt={val.title} />
                  </div>
                  <h4 className='title text-center'>
                    {val.title}
                    {/* <Link
                      to='#service'
                      dangerouslySetInnerHTML={{ __html: val.title }}></Link> */}
                  </h4>
                  <p
                    className='description pt--5 text-center'
                    style={{ height: "300px" }}
                    dangerouslySetInnerHTML={{ __html: val.description }}></p>
                </div>
                {/* <div className='image'>
                  <img src={`${val.image}`} alt='card Images' />
                </div> */}
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFive;
