import React from "react";
import ContactForm from "./ContactForm";
import ScrollAnimation from "react-animate-on-scroll";
import { FiMail, FiCalendar } from "react-icons/fi";
import { PopupButton } from "react-calendly";

const ContactOne = () => {
  return (
    <>
      <div className='row row--15'>
        <div className='col-lg-12'>
          <div className='rn-contact-address mt_dec--30'>
            <div className='row' style={{ justifyContent: "space-evenly" }}>
              {/* <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiHeadphones />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Our Phone Number</h4>
                    <p>
                      <a href='tel:+444 555 666 777'>+1 (917) 783-8003</a>
                    </p>
                  </div>
                </div>
              </div> */}

              <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMail color='var(--color-secondary)' />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Send Us An Email</h4>
                    <p>
                      <a href='mailto:info@vangotech.us'>info@vangotech.us</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiCalendar color='var(--color-secondary)' />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Schedule A Discovery Call</h4>
                    <p>
                      <PopupButton
                        url='https://calendly.com/vangotech/discovery-call'
                        /*
                         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                         */
                        rootElement={document.getElementById("root")}
                        text='Schedule Here'
                        className='btn-default'
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='row mt--40 col-lg-12'
        style={{ justifyContent: "center" }}>
        <div className='col-lg-7 text-center'>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}>
            <h2
              className='title w-600 mb--20'
              dangerouslySetInnerHTML={{
                __html: "Or send us a note below.",
              }}></h2>
          </ScrollAnimation>
          <ContactForm formStyle='contact-form-1' />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
